export const mockPlaces = [
  { id: 1, value: 'Idar' },
  { id: 2, value: 'Kanpur' },
  { id: 3, value: 'Ahmedabad' },
  { id: 4, value: 'Bangalore' },
  { id: 5, value: 'Mumbai' },
  { id: 6, value: 'Delhi' },
  { id: 7, value: 'Chennai' },
  { id: 8, value: 'Hyderabad' },
  { id: 9, value: 'Pune' }
]
